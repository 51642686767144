import { Grid, GridItem, GridRow } from 'components/Grid';
import { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import Image from 'next/image';
import { customImageLoader } from 'components/NextImage';
import { IntroTextImage } from './components/IntroTextImage';
import { IntroTextContext } from './components/IntroTextContext';
import CookieImage from './Cookie-Hohoho.png';

export interface IIntroText {
  description?: string | null;
  title?: string | null;
  super?: string | null;
  titleTag?: string | null;
}

export const IntroText = ({
  super: superText,
  titleTag,
  title,
  description,
  themeName,
  theme,
}: IIntroText & ISharedTemplateProps) => {
  return (
    <div className="relative break-words w-full">
      <Grid>
        <GridRow columns={16}>
          {themeName === 'christmas_blue_edition' ||
            (themeName === 'christmas' && (
              <GridItem colStart={1} colSpan={3}>
                <Image
                  src={CookieImage}
                  alt="Ho ho image"
                  width={300}
                  height={400}
                  loader={customImageLoader}
                  className="hidden md:block relative h-full w-full"
                />
              </GridItem>
            ))}
          <GridItem colStart={4} colSpan={10} className="text-center px-1/10">
            <IntroTextContext
              superText={superText}
              title={title}
              theme={theme}
              titleTag={titleTag}
              description={description}
              themeName={themeName}
            />
          </GridItem>
        </GridRow>
      </Grid>
      {themeName === 'christmas_blue_edition' || (themeName === 'christmas' && <IntroTextImage />)}
    </div>
  );
};
