import { Theme } from '../../../styles/getThemeConfig';
import { AVAILABLE_THEMES_TYPE } from '@/styles/constants';
import { SuperTextElement } from './SuperTextElement';
import { TitleElement } from './TitleElement';
import { DescElement } from './DescElement';

interface IIntroTextContext {
  theme?: Theme;
  description?: string | null;
  title?: string | null;
  superText?: string | null;
  titleTag?: string | null;
  themeName?: AVAILABLE_THEMES_TYPE;
}

export const IntroTextContext = ({
  superText,
  title,
  titleTag,
  description,
  themeName,
}: IIntroTextContext) => {
  return (
    <>
      {superText && <SuperTextElement tag="sup" text={superText} themeName={themeName} />}
      {title && <TitleElement tag={titleTag || 'div'} text={title} themeName={themeName} />}
      {description && (
        <DescElement
          data-test="text-description"
          tag="p"
          themeName={themeName}
          text={description}
        />
      )}
    </>
  );
};
