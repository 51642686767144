import { ParagraphText } from '@/components/ParagraphText';
import { AVAILABLE_THEMES } from '@/styles/constants';
import { Text as Paragraph } from '@/components/Typography';
export const DescElement = ({
  text,
  themeName,
}: {
  text: string;
  themeName?: string;
  tag?: string;
}) => {
  if (themeName === AVAILABLE_THEMES.suprema) {
    return (
      <ParagraphText data-test="text-description" type="m">
        {text}
      </ParagraphText>
    );
  }

  return (
    <Paragraph data-test="text-description" tag="p" type="lg">
      {text}
    </Paragraph>
  );
};
