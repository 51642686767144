import { Headline } from '@/components/Headline';
import { Title } from '@/components/Typography';
import { AVAILABLE_THEMES } from '@/styles/constants';

export const TitleElement = ({
  text,
  themeName,
  tag,
}: {
  text: string;
  themeName?: string;
  tag?: string;
}) => {
  if (themeName === AVAILABLE_THEMES.suprema) {
    return (
      <Headline as="h3" type="l" className="font-primary mb-2">
        {text}
      </Headline>
    );
  }

  return (
    <Title
      className="pt-2.5 lg:pb-7.5 md:pb-5 sm:pb-5"
      tag={tag || 'div'}
      type={themeName ? 'xl' : 'md'}
    >
      {text}
    </Title>
  );
};
