import { ParagraphText } from '@/components/ParagraphText';
import { AVAILABLE_THEMES } from '@/styles/constants';
import { Text as Paragraph } from '@/components/Typography';

export const SuperTextElement = ({
  text,
  themeName,
  tag,
}: {
  text: string;
  themeName?: string;
  tag?: 'sup' | 'span' | 'p';
}) => {
  if (themeName === AVAILABLE_THEMES.suprema) {
    return (
      <ParagraphText className="top-0 uppercase" as={tag} type="xs">
        {text}
      </ParagraphText>
    );
  }

  return (
    <Paragraph className="top-0" tag={tag ?? 'div'} type="lead">
      {text}
    </Paragraph>
  );
};
