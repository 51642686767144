import Image from 'next/image';
import { customImageLoader } from '../../NextImage';
import HeartCookie from '../Heart-Cookie.png';
import Heart from '../Heart.png';

export const IntroTextImage = () => {
  return (
    <>
      <Image
        src={HeartCookie}
        alt="Heart Cookie"
        width={624}
        height={489}
        loader={customImageLoader}
        className="hidden md:block absolute right-0 md:-top-24 md:max-w-[27vw] lg:max-w-[20vw]"
      />
      <Image
        src={Heart}
        alt="Heart"
        width={354}
        height={325}
        loader={customImageLoader}
        className="md:hidden absolute right-0 -top-16 max-w-[25vw]"
      />
    </>
  );
};
